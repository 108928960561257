.galeria-view {
    width: 100%; height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.galeria-view .galeria {
    width: 100%;
    text-align: center;
}
.galeria-view .galeria .title {
    margin-top: 2rem;
}
.galeria-view .galeria .title h1{
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 1.5rem;
}
.galeria-view .galeria .galeria-nav {
    width: 100%;
}
.galeria-view .galeria .galeria-nav button {
    padding: 0.5rem 0.5rem;
    margin: 0 0.5rem;
    background-color: rgb(84, 170, 127);
    color: white;
    font-size: 18px;
    border-radius: 0.2rem;
    cursor: pointer;
    border: none;
}
.galeria-view .galeria .galeria-nav button.selected{
    background-color: rgb(56, 61, 59);
    color: rgb(84, 170, 127);
}

