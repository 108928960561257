.footer {
    display: flex;
    justify-content: space-around;
    background-color: rgb(83, 155, 128);
    width: 100%; height: 14rem;
    bottom: 0;
    padding: 0 2rem;
    margin-top: 4rem;
}
.footer .info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.footer-nav ul li {
    list-style: none;
    padding: 0.5rem 1rem;
}
.footer-nav ul li a {
    color: azure;
    text-decoration: none;
}

.footer .info-contact {
    width: 100%;
    display: flex;

}
.footer .info-contact .info .location-link {
    display: flex; align-items: center;
    padding: 1rem; margin: 0;
    text-decoration: none;
    font-size: 24px;
    color: rgb(7, 251, 170);
}
.footer .info-contact .info .location-link:hover{
    color: rgb(24, 203, 235);
    
}
.footer .info-contact .info .location-link .location-icon{
    
}
.footer .info-contact .info .location-link p {
    padding: 0rem; margin: 0;
    margin-left: 1rem;
}
.footer .info-contact .info h5 {
    color: white;
}

.footer .info-contact .info .icons-contact-container {
    width: 100%; height: 2rem;
}
.footer .info-contact .info .icons-contact-container .icons-contact {
    width: 100%; 
}
.footer .info-contact .info .icons-contact-container .icons-contact a img {
    width: 2rem; height: 2rem;
    margin-right: 1rem;
}

.footer .info-contact .map{
    width: 50%;height: 10rem;
    background-color: azure;
}

.footer .map-conteiner {
    width: 100%; height: 100%;
    margin-bottom: 1rem;
}
@media only screen and (max-width: 780px){
    .footer {
        flex-direction: column;
        height: 35rem ;
        padding-top: 1.5rem;
    }
    .footer-nav {
     display: none;   
    }
    .footer .info-contact {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .footer .info {
        width: 100%;
        height:10rem;
        padding-top: 1.5rem;
        margin-bottom: 2rem;
    }
    
    .footer .info-contact .map {
        width: 100%; height: 15rem;
    }
}