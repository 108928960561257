.agend-slider {
    padding-top: 3rem;
    padding-bottom: 3rem;
    height: 100%;
    text-align: center;
    border: rgb(72, 204, 160) 2px solid;
    border-radius: 0.5rem;
}
.agend-slider h2 {
    font-size: 22px;
}
.agend-slider .agend-date {
    display: flex;
    align-items: center;
}
.agend-slider .agend-date .agend-day h3 {
   font-size: 18px;
   color: rgb(91, 134, 125); 
}

.agend-slider .agend-content h3 {
    font-size: 20px;
}
.agend-item {
    width: 100%; max-height: 15rem;
    padding: 0 3rem;
    margin-top: 2rem;
    text-align: left;
}
.agend-item h2 {
    font-size: 22px;
}
.agend-item h5 {
    font-size: 16px;
    font-weight: 300;
    height: 60%;
    overflow: hidden;
}
@media only screen and (max-width: 780px){ 
    .agend-slider {
        width: 100%; height: auto;
        padding-top: 2rem;
        margin-top: 1rem;
        margin: 0 0;
    }
    .carousel-indicators {
        display: none;
    }

    .agend-item {
        padding: 0 1rem;
    }
}