.redes-bar {
    width: 100%; height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;

}
.redes-bar ul {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
}
.redes-bar ul li {
    padding: 0.5rem;
    margin: 0.5rem;
    list-style:none;
    border: 1px solid rgba(250, 250, 250, 0.37);
    border-radius: 0.5rem;
}
.redes-bar ul li a {
    text-decoration: none;
}
.redes-bar .icon {
    max-width: 2rem;
}
    