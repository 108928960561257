.q-somos-view {
    width: 100%; height: 100%;
    display: flex;
    
    background-image: url('/public/img/quienes-somos.webp');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    
    
}
.title {
    align-items: center;
    justify-content: center;
}
.q-somos {
    display: flex;
    margin-top: 2rem;
    padding: 0 5rem 0 0;
}
.q-somos div .information-section {
    height: 100%;
    padding: 0 5rem 0 0;
    align-items: center;
}
.q-somos div .information-section .item-section {
    margin-top: 2rem;
    margin-bottom: 3rem;
}
.q-somos div .information-section .item-section h2{
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 1.5rem;
}
.q-somos div .information-section .item-section p {
    font-size: 22px;
    letter-spacing: 0.5px;
}

.q-somos-view .q-somos-title {
    display: flex;
    align-items: flex-end;
    height: 55vh;
    color: rgb(255, 255, 255);

}
.q-somos-view .q-somos-title .title {
    text-align: center;
    margin-bottom: 2rem;
}
.q-somos-view .q-somos-title .title h1 {
    font-size: 55px;
    letter-spacing: .5px;
    font-weight: 400;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.452);

}


@media only screen and (max-width: 780px) {
    
    .q-somos-view {
        height: 25%;
        background-size: auto 100%;
        background-position: center;
    }


    .q-somos {
       
        padding: 0 2rem;
        justify-content: center;
    }
    .q-somos div {


        flex-direction: column;
        
    }
    .q-somos div .information-section {
    margin: 0; padding: 0;
    }

    .q-somos-view .q-somos-title {
        height: 65vh;
    }
}