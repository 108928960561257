.payments-container {
    width: 80%; max-height: 50%;
    padding: 1rem 1rem;
    margin: 0 0 2rem 0;
    text-align: center;
    border: solid 2px rgb(83, 145, 124);
    border-radius: 0.2rem;
}
.payments-container h2 {
    font-size: 20px;
    font-weight: 600;

}
.payments-container h3 {
    font-size: 20px;
    font-weight: 700;
    padding: 0 2rem;
    margin: 1rem 0;
    text-align: center;
}

.payments-container .type-buttons-container .buttons {
    width: 100%;
    display: flex; flex-direction: row;
    justify-content: center;
    margin-bottom: 0.5rem;
}
.payments-container .type-buttons-container .buttons div{
    margin: 0.5rem;
}
.payments-container .type-buttons-container .buttons div.selected{
    color: rgb(119, 233, 208);
}
.payments-container .type-buttons-container h3{
    font-size: 18px;

}

.payments-container .form {
    display: flex; flex-direction: column; justify-content: center;
    padding: 0 2rem;
    margin: auto;
    margin-top: 2rem;
}
.payments-container .form input {
    text-align: center;
    margin-bottom: 1rem;
}
.payments-container .form select {
    text-align: center;
    margin-bottom: 1rem;
    width: 100%;
}
.payments-container .form select option {
    width: 100%;
}
.payments-container .form h4 {
    font-size: 16px;
    margin-bottom: 0.5rem;
}

@media only screen and (max-width: 780px) {
    .payments-container {
        padding: 0 1rem;
    }
    .payments-container .form {
        padding: 0;
    }
    .payments-container .form button {
        margin: 0.5rem;
    }
}

@media only screen and (max-width: 480px){
    .payments-container {
        width: 100%;
    }
    .payments-container h3 {
        padding: 0;
        font-weight: 200;
    }


    .donation-container .payment-options-container .transfer-info-container {
        width: 100%;
    }
}