.inicio-view {
    margin: 0; padding: 0;
    width: 100%; height: 100%;
    
    
}
.inicio-view .inicio-portada {
    margin: 4rem 0 0 0;
    width: 100%; height: 35rem;
    padding: 0 10%;
    display: flex;
    text-align: center;
    align-items: center;
    text-align: center;
}

.inicio-view .inicio-portada .img-content {
    position: absolute;
    left: 0;
    width: 100%; height: 35rem;
    margin: auto auto;
    background-image: url('/public/img/quienes-somos.webp');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: brightness(0.4);
}
.inicio-view .inicio-portada h3 {
    color: rgb(255, 255, 255);
    font-size: 32px;
    letter-spacing: .5px;
    font-weight: 100;
    z-index: 10;
   
}
.section-1 .logo-title {
    display: flex;
    align-items: center;
}
.section-1 .logo-title .logo {
    max-width: 7rem;
    
}
.section-1 .title {
    align-items: center;
    justify-content: center;
}
.section-1 .title h1 {
    font-size: 42px;
    font-weight: 700;
    line-height: 0.9;
}
.section-1 .title h3 {
    padding: 0.5rem 0 1rem 0;
    font-size: 18.5px;
    font-weight: 300;
    font-family: 'Archivo Narrow', sans-serif;
}
.section-1 .title .title-button-container {
    display: flex;
    width: 100%;
    margin-top: 2rem;
}
.section-1 .title .title-button-container a{
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    margin: 0 1rem 0 0;
    border-radius: 0.2rem;
    color: azure;
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    background-color:rgb(54, 160, 137);
    border: none;
    
}
.section-1 .title .title-button-container a p {
    display: flex;
    align-items: center;
    margin: 0; padding: 0;
}

.section-1 {
    width: 100%; height: 100vh;
    display: flex;
    align-items: center;
}
.section-1 .img-container {
    width: 100%; height: 100%;
    padding-right: 5rem;
    background-image: url('/public/img/foto-portada.png');

    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center;
    
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(40%);
 
}
.section-1 .img-container img{
    
    width: 20rem;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(40%);
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 4px 5px 20px 5px rgba(53, 53, 53, 0.377);
    
}
.section-1 .img-container img:nth-child(1n){
    position: relative;
    top: 45%;
    left: 55%;
}
.section-1 .img-container img:nth-child(2n){
    
    position: relative;
    top: -10%;
    left: 20%;
}
.section-1 .img-container img:nth-child(3n){
    position: relative;
    top: 0%;
    left: 10%;
}



.section-2 {
    width: 100%; height: 100%;
    padding: 5rem 1.5rem 0;
    margin-bottom: 5rem;
}
.section-2 .information-section{
    height: 100%;
    padding: 0 5rem 0 0;
    align-items: center;
    
    
}
.section-2 .information-section .item-section{
    margin-top: 3rem;
}
.section-2 .information-section .item-section h2 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 1.5rem;
}
.section-2 .information-section .item-section p {
    font-size: 22px;
    letter-spacing: 0.5px;
}


.section-2 .information-section a {
    width: 100%; height: 3rem;
    font-size: 16px;
    text-decoration: none;
    color: white;
    background-color:rgb(98, 161, 148);
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
    
}
.section-2 .agenda-slider{
    height: 100%;
}
.section-2 .agenda-slider .agenda-title{
    
    margin-top: 3rem;
    
}
.section-2 .agenda-slider .agenda-items{
    
    margin-top: 1rem;
    
}
@media(max-width:900px){
    .section-1 .img-container {
        
    }
}
@media(max-width:780px){
    .section-1{
        
        flex-direction: column;
    }

    .section-1 .img-container {
        width: 100%;
        height: 65%;
        position: absolute;
        padding-top: 2rem;
        z-index: -10;
        overflow: hidden;
    }
    .section-1 .img-container .img {
        width: 16rem;
    }
    .section-1 .title {
        width: 100%; height: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 100%;
        margin-top: 5rem;
    }
    .section-1 .logo-title {
        margin-bottom: 1rem;
    }
    .section-1 .logo-title .logo {
        max-width: 6rem;
        margin: 0;
    }
    .section-1 .title h1 {

        font-family: 'Barlow Condensed', sans-serif;
        font-size: 32px;
        font-weight: 700;
        align-items: center;

        line-height: 1;
    }
    .section-1 .title h3 {

        font-family: 'Archivo Narrow', sans-serif;
        font-size: 18px;
        font-weight: 200;
        letter-spacing: 1px;
        line-height: 1;
        padding: 0 0.7rem;
        margin-bottom: 1rem;
    }
    .section-1 .img-container .img {
        width: 10rem;
    }
    .section-1 .img-container img:nth-child(1n){
        position: relative;
        top: 31%;
        left: 35%;
        
    }
    .section-1 .img-container img:nth-child(2n){
        
        position: relative;
        top: 15%;
        left: 5%;
    }
    .section-1 .img-container img:nth-child(3n){
        position: relative;
        top: 8%;
        left: 6%;
    }
    .section-1 .title .title-button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }
    .section-1 .title .title-button-container a{
        width: 90%;
        
        margin: 0;
        margin-bottom: 1rem;
        overflow:hidden;
        
    }
    .section-1 .title .title-button-container a p{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        margin: 0;
        
    }
    .section-1 .title .title-button-container {
        margin-top: 0.5rem;
    }
    .section-2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .section-2 .information-section {
        height: 100%;
        padding: 0; margin-bottom: 8rem;
    }
    .section-2 .information-section .item-section{
       
    }
    .section-2 .information-section a {
        width: 100%; height: 3rem;
        font-size: 16px;
        text-decoration: none;
        color: white;
        background-color:rgb(98, 161, 148);
        padding: 0.5rem 1rem;
        border-radius: 0.4rem;
        margin: 0 0 0 60% ;
    }

    .section-2 .agenda-slider{
        width: 100%;
        height: 100%;
    }
    .section-2 .agenda-slider .agenda-title{
        
        margin-top: 3rem;
        
    }
    .section-2 .agenda-slider .agenda-items{
        
        margin-top: 1rem;
        
    }
}
@media (min-width:381px) and (max-width:760px){
    
   
    .section-1 .title h1 {
        font-family: 'Barlow Condensed', sans-serif;
        font-size: 36px;
        font-weight: 600;
        line-height: 0.8;
    
        
       
        
    }
    .section-1 .title h3 {

        font-family: 'Archivo Narrow', sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 1;
        padding: 0 0.7rem;
    }

    .section-1 .img-container {
        margin-top: 2rem;
    }
    .section-1 .img-container .img {
        width: 18rem;
    }
    .section-1 .img-container img:nth-child(1n){
        max-width: 16rem;
        position: relative;
        top: 20%;
        left: 35%;
        
    }
    .section-1 .img-container img:nth-child(2n){
        
        position: relative;
        top: 15%;
        left: 2%;
    }
    .section-1 .img-container img:nth-child(3n){
        position: relative;
        top: -25%;
        left: 6%;
    }

}
@media(max-width:381px) {
    .section-1 .img-container .img {
        width: 16rem;
    }
    .section-1 .img-container img:nth-child(1n){
        max-width: 14rem;
        position: relative;
        top: 20%;
        left: 36%;
        
    }
    .section-1 .img-container img:nth-child(2n){
        
        position: relative;
        top: 15%;
        left: 2%;
    }
    .section-1 .img-container img:nth-child(3n){
        position: relative;
        top: -28%;
        left: 6%;
    }

    .inicio-view .inicio-portada h3 {
 
        font-size: 24px;
    }
    .section-1 .title h1 {
        font-size: 34px;
        font-weight: 600;
        line-height: 0.9;
    }
    .section-1 .logo-title {
        margin: 2rem 0 0.5rem 0;
    }
    .section-1 .logo-title .logo {
        max-width: 5rem;
        margin: 0;
    }
}