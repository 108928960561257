.nav {
    width: 60%; height: 100%;
}
.nav ul {
    width: 100%; height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.nav ul li {
    list-style: none;
}
.nav ul li .link {
    text-decoration: none;
    color: rgb(13, 14, 13);
    font-size: 18px;
}

@media (min-width:320px) and (max-width:760px)  {
    .nav {
        background-color: rgb(215, 240, 226);
        position: absolute;
        right: 0; top: 0;
        height: 100vh; width: 100%;
        display: none;
    }
    .nav ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .nav ul li{
        width: 100%; height:5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(0, 0, 0, 0.116);
        border-radius: 0.2rem;
        margin-bottom: 0.5rem;
        
    }
    .nav ul li .link{
        font-size: 22px;
        width: 100%; height: 100%;
        display: flex;
        justify-content: center; align-items: center;
    }
}

@media (min-width:381px) and (max-width:760px) {
    
}