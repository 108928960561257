.contact-section{
    width: 100%; height: 100%;
    margin: 2rem 0 ;
    text-align: center;
}
.contact-section h2{
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.contact-section .contact-content {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}

.contact-section .contact-content .contact-redes h3 {
    font-size: 22px;
    margin-bottom: 1rem;
}

.contact-section .contact-content .contact-social {
    display: flex;
    align-items: center;
    justify-content: center;
}
.contact-section .contact-content .contact-social {
    display: flex;
    
    justify-content: center;
}
.contact-section .contact-content .contact-social a {
    margin: 0 0.5rem;
}
.contact-section .contact-content .contact-social a img {
    max-width: 3rem;

}


.contact-section .contact-content .contact-form h3 {
    font-size: 22px;
    margin-bottom: 1rem;
}


.contact-section .contact-content .contact-form form {

    width: 75%;
    margin: auto;
}
.contact-section .contact-content .contact-form form .form-level {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}
.contact-section .contact-content .contact-form form .form-level input {
    text-align: center;
    height: 2rem;
}
.contact-section .contact-content .contact-form form .form-level textarea {
    text-align: center;
    height: 6rem;
} 

@media only screen and (max-width: 780px) {
    .contact-section .contact-content {
        flex-direction: column;
    }
    .contact-section .contact-content .contact-form {
        margin-bottom: 3rem;
    }
    .contact-section .contact-content .contact-form form {
        padding: 0;
    }
}