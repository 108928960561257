.photo-gallery {
    width: 100%; height: 45vh;
    text-align: center;
}
.photo-gallery h2{
    font-size: 22px;
    background-color: rgb(159, 223, 198);
    padding: 1rem 0;
}

.gallery-container {
    width: 100%; height: 100%;
}
.gallery-container .carousel-header{
    width: 100%; height: 100%;
}
.gallery-container .carousel-header .carousel-indicators button {

    background-color: rgb(36, 53, 49);
}
.gallery-container .carousel-header .carousel-inner{
    width: 100%; height: 100%;
}
.gallery-container .carousel-header .carousel-inner .carousel-item{
    width: 100%; height: 100%;

    
}
.gallery-container .carousel-header .carousel-inner .carousel-item div{
    width: 100%; height: 100%;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.gallery-container .carousel-header .carousel-inner .carousel-item img {
     max-height: 14rem;
    margin: 0 0.2rem;
    object-fit:fill;
    border-radius: 0.2rems;
    
}
.gallery-container a {
    text-decoration: none;
    list-style: none;
    color: white;
    background-color: rgb(72, 158, 122);
    padding: 0.5rem 1rem;
    border-radius: 0.2rem;

}
@media only screen and (max-width: 1024px) {
    .gallery-container .carousel-header .carousel-inner .carousel-item img {
        max-height: 12rem;
    
       
   }
}

@media only screen and (max-width: 780px) {
    .photo-gallery {
        width: 100%; height: 90vh;
     
    }
    .gallery-container .carousel-header .carousel-inner .carousel-item div {
        flex-direction: column;
        justify-content: space-center;
    }
    .gallery-container .carousel-header .carousel-inner .carousel-item img {
        
        height: 33%;
        margin: 0.5rem 0;
        max-width: 100%;
    }
}