.viewer-carousel {
    width: 100%; height: 100%;
    position: fixed;
    top: 0; left: 0; z-index: 100;
    
    background-color: black;
    align-items: center;
    justify-content: center;
    
   
    

} 

.viewer-carousel .carousel-item {
    width: 100vw; height: 100vh;
    align-items: center;
    justify-content: center;

}
.viewer-carousel .viewer-item  .item-img {
    height: 100vh; width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    
}
.viewer-carousel .viewer-item  .item-img img {
    max-height: 100vh;
    max-width: 100vw; 
    object-fit: contain;
}
