.photo-gallery-container {
    width: 100%;
    display: flex; flex-wrap: wrap;
    justify-content: center;
    padding: 1rem 0 1rem 0;
    
}
.photo-gallery-container .exit-icon {
    margin-top: 2rem;
    
}

.photo-gallery-container .card-item {
    margin-top: 0.5rem;
 
}