.donation {
    display: flex; flex-direction: column;
    justify-content: center;align-items: center;
    width: 100%; 
    padding: 2rem;
    text-align: center;
}

.donation a {
    text-decoration: none;
    list-style: none;
    color: white;
    background-color: rgb(72, 158, 122);
    padding: 0.5rem 1rem;
    border-radius: 0.2rem;
}
.donatioin h2 {
    text-align: center;
}