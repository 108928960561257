.agenda-view {
    width: 100%; height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.title {
    align-items: center;
    justify-content: center;
}

.c-ayudar-view .agend-container {
    margin-top: 2rem;
    text-align: center;
}
.c-ayudar-view .agend-container .agend-title h2 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 1.5rem;
}
.c-ayudar-view .agend-container .agend-title h3 {
    font-size: 26px;
    font-weight: 400;
}


.c-ayudar-view .agend-container .items-container{
    display: flex;
    flex-wrap: wrap;
    justify-content:  center;
    margin-top: 3rem;
    
}

.c-ayudar-view .agend-container .items-container h3 {
    font-size: 22px;
}
.c-ayudar-view .agend-container .items-container .agend-item{
    background-color: rgb(174, 219, 202);
    border-radius: 0.2rem;
    padding: 1rem; margin: 1rem;
    max-width: 33%;
    
}

.c-ayudar-view .agend-container .items-container .agend-item .agend-date .agend-day h3 {
    font-size: 18px;
    color: rgb(105, 105, 105);
}

@media only screen and (max-width: 780px) {
    .c-ayudar-view .agend-container .items-container .agend-item {
        max-width: 100%;
    }
}