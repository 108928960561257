.c-ayudar-view {
    width: 100%; height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.donation-container {
    width: 100%; height: 100%;
    padding: 1rem;
    padding-top: 3rem;
    display: flex; flex-direction: row;

}

.donation-container .video-container {
    width: 50%; min-height: 100%;
    display: flex; flex-direction: column;
    align-items: center;
}
.donation-container .video-container h2 {
    font-size: 24px; font-weight: 700;
    padding: 0 4rem;
    margin-bottom: 1rem;
    text-align: center;
}.donation-container .video-container h3 {
    font-size: 20px;
    padding: 0 4rem;
    margin-bottom: 2rem;
    text-align: center;
}
.donation-container .video-container .frame{
    height: 45%;
    width: 100%;
    padding: 1rem;
}
.donation-container .payment-options-container {
    width: 50%; height: 100%;
    display: flex; flex-direction: column;
    align-items: center;
}
.donation-container .payment-options-container h2 {
    font-size: 24px;
    font-weight: 700;
    padding: 0 4rem;
    margin-bottom: 1rem;
    text-align: center;
}

.donation-container .payment-options-container .transfer-info-container {
    display: flex; flex-direction: column;
    align-items: center;
    width: 80%;
    padding: 2rem;
    border: solid 2px rgb(83, 145, 124);
    border-radius: 0.2rem;

    display: none;
    
}
.donation-container .payment-options-container .transfer-info-container h3 {
    font-size: 20px;
    padding: 0 2rem;
    margin-bottom: 2rem;
    text-align: center;
    font-weight: 700;
}
.donation-container .payment-options-container .transfer-info-container .transfer-info {
    display: flex; flex-direction: column;
    align-items: center; justify-content: center;
    padding: 1rem;
    background-color: rgb(92, 124, 113);
    color: white;
    border-radius: 0.2rem;
}
.donation-container .payment-options-container .transfer-info-container .transfer-info h3{
    margin: 0; padding: 0.5rem;
    font-size: 18px; 
    font-weight: 100;
}
@media only screen and (max-width: 780px) {
    .donation-container {
        width: 100%; height: 100%;
        padding: 1rem;
        padding-top: 3rem;
        display: flex; flex-direction: column;
    
    }
    .donation-container .video-container {
        width: 100%;
        margin-bottom: 2rem;
    }
    .donation-container .video-container .frame {
        height: 20rem; width: 75%;
    }

    .donation-container .payment-options-container {
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .donation-container .video-container h2 {
        padding: 0;
    }
    .donation-container .video-container h3 {
        padding: 0;
    }

    .donation-container .video-container .frame {
        width: 100%; height: auto;
        padding: 0;
    }


    .donation-container .payment-options-container h2 {
        padding: 0;
    }


    .donation-container .payment-options-container .transfer-info-container h3 {
        padding: 0;
    } 
}