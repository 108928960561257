.header {
    width: 100%; 
    position: fixed;
    z-index: 200;
    background-color: rgb(200, 230, 210);
    display: flex;
    align-items: center;
     
}

.nav-bar-icon {
    display: none ;
}
.header .logo {
    z-index: 500;
}
.header .logo img {
    width: 3rem;
    margin: .5rem;
}
@media (min-width:320px) and (max-width:760px) {
    .header {
        display: flex;
        justify-content: space-between;
        padding: 0 0.5rem;
        
    }
    .nav-bar-icon {
        font-size: 25px;
        padding: 0.5rem;
        display: flex;
        z-index: 100;

    }
}
@media (min-width:381px) and (max-width:760px) {
  
}