@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow:wght@500;600;700&family=Barlow+Condensed:wght@100;200;300;400;500;600;700&family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}
body {
  width: 100%; height: 100%;
  margin: 0;
  padding: 0;
  
  font-family: 'Archivo Narrow', sans-serif;
  background-color: rgb(244, 247, 223);

  overflow-x: hidden;
}

